.outerbox{
  border: solid 1px rgb(17, 16, 16);
  padding: 10px 12px;
  padding-right: 14px;
}
.vl {
    border-left: 1px solid rgb(166 156 156);
    height: 163px;
    position: absolute;
    margin-left: -3px;
    top: 5px;
  }
  .hl{
    color:rgb(166 156 156)!important;
    border-top: 1px solid rgb(166 156 156)!important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cyclehead{
    font-size: 14px;
    font-weight: bold;
    color: #203864;
  }
  .imgflow{
    width: 42px;
  }
  .leftborder{
    border-left: 1px solid rgb(166 156 156);
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .templatetext{
    margin-left: -15px
  }
  .tem-color{
    font-size: 12px;
    color: #203864;
  }
  .emphead{
    font-size: 14px;
    color: #203864;
    font-weight: bold;
  }
  .tem-rate{
    font-size: 16px;
    font-weight: bold;
  }
  .circle>tspan{
    display: none!important;
  }
  .publish{
    background-color: #59D99D;
    padding: 6px 20px!important;
    font-size: 12px!important;
    color: rgba(255,255,255,1)!important;
  }
  .blueexport{
    background-color: #295BDC;
    padding: 6px 20px!important;
    font-size: 12px!important;
    color: rgba(255,255,255,1)!important;
  }
  .removebtn{
    background-color: #EE4C58;
    font-size: 13px!important;
    padding: 6px 20px!important;
    color: rgba(255,255,255,1)!important;
  }
  .adduser{
    width: 16px;
  }
  .viewcycle{
    font-size: 10px;
    margin-right: -15px;
  }
  .adduser .mat-dialog-container {
    position: absolute;
    top: 40px!important;
    left: 150px!important;
    width: 980px!important;
  }
  .vertical{
    width: 1%!important;
    margin-left: -42px!important;
  }
  .emppad{
    margin-right: 5px;
}
.drop-select-emp {
    width: 209px;
    height: 33px;
    border-color: #cbc3c3;
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
}
.drop-select-template{
    width: 308px;
    height: 33px;
    border-color: #cbc3c3;
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
}
.recalled-color{
    background-color: #E9BAAC!important;
    color: #d83c0d!important;
    font-size: 12px;
    border-radius: 18px;
    padding: 2px 18px;
}
.completed-color{
  background-color: #A1F8F3!important;
  color: #04948d!important;
  font-size: 12px;
  border-radius: 18px;
  padding: 2px 18px;
}
.inprogress-color{
  background-color: #A7BCFA!important;
  color: #2b48a0!important;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 18px;
}
.assigned-color{
  background-color: #1b1503!important;
  color: #9c7917!important;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 18px;
}
.edit-review-color{
  background-color: #e64901!important;
  color: #1d1d1d!important;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 18px;
}
.title-emp{
  position: absolute;
  top: -25px;
}
.takereview{
  background-color: #A7BCFA!important;
  color: #2b48a0!important;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 18px;
}
.submitted{
  background-color: #C8E5E0!important;
  color: #56C1AB!important;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 18px;
}
/* .tab .nav-tabs li[class="active"] a #Path_360 {
  fill:rgba(41,91,220,1);
}
.tab .nav-tabs li[class="active"] a #Path_361 {
  fill:rgba(41,91,220,1);
}
.tab .nav-tabs li[class="active"] a #Path_356 {
  fill:rgba(41,91,220,1)!important;
}
.tab .nav-tabs li[class="active"] a #Path_357 {
  fill:rgba(41,91,220,1)!important;
}
.tab .nav-tabs li[class="active"] a #Path_358 {
  fill:rgba(41,91,220,1)!important;
} */
.blueheadreview{
  margin-bottom: 12px !important;
  margin-top: 12px !important;
  text-align: left !important;
  font-size: 14px !important;
  color: #003385;
  text-transform: none;
  font-weight: bold !important;
}
.bluebackground{
  background-color: #EEF5F9;
  padding: 10px;
  margin-bottom: 20px !important;
  margin-left: 0px!important;
  margin-right: 0px!important;
}
/* .angular-text .angular-editor-toolbar
{
  background-color:rgb(170 170 170)!important;
} */
.angular-text .angular-editor-textarea
{
  background-color:#f5f5f5 !important;
  overflow: scroll!important;
}
.angular-text .angular-editor-button
{
  background-color:#f5f5f5 !important;
}
.angular-text .ae-font .ae-picker-label 
{
  background-color:#f5f5f5 !important;
}
.angular-text .ae-font .ae-picker-label:before 
{
  background-color:#f5f5f5 !important;
}
.angular-editor-textarea{
  border:1px solid #777474eb!important;
  background-color:white!important;
  overflow: scroll!important;
}
.angular-editor-textarea:focus-visible{
  border:none !important;
  background-color:white!important;
  overflow: scroll!important;
}
.attribute-styles
{
  margin-bottom:12px !important;
}
.attribute-styles .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-bottom: 1px!important;
}
.attribute-styles .ng-select .ng-select-container
{
  background-color:#f5f5f5 !important;
}
.attribute-styles
{
  margin-bottom:12px !important;
}
.attribute-styles .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-bottom: 1px!important;
}
/* .attribute-styles .ng-select .ng-select-container
{
  background-color:#eef5f9 !important;
} */
.attributes-style
{
  margin-bottom:12px !important;
}
.attributes-style .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-bottom: 1px!important;
}
.attributes-style .ng-select .ng-select-container
{
  background-color:#eef5f9 !important;
}
.attributes-styles
{
  margin-bottom:12px !important;
}
.attributes-styles .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-bottom: 1px!important;
}
.attributes-styles .ng-select .ng-select-container
{
  background-color:transparent !important;
  border: none;
}
.attributes-styles .ng-select .ng-select-container:hover
{
  background-color:transparent !important;
  border: none;
}
.attributes-styles .ng-select .ng-arrow-wrapper {
display: none !important;
}



.collapseblueplus{
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color:rgb(13 72 226);
  padding: 0px 6px;
  margin-top: 15px;
  position: absolute;
  left: 22px;
}
.textmargin{
  margin-left: 12px;
}

.blueheadcustomer{
  text-align: left !important;
  font-size: 16px !important;
  color: #003385;
  text-transform: capitalize !important;
  font-weight: bold !important;
  margin-bottom: 12px;
}

.comp-desc{
  padding-left: 18px;
}
.elem-desc{
  display: inline-block !important;
  padding-left: 27px;
}

.rate{
  margin-right:10px;
  font-weight: bold;
}

.rows
{
  display: flex;
  justify-content: space-between;
}