.box{
width:18%!important;
margin-right: 8px;
border: 1px solid #d0cbcb;
border-radius: 6px;
height: 49px;
}
.activebtnsec{
  margin-top: 7px;
}
.filterdiv{
  padding-right: 32px!important;
}
.active-btn{
  background-color: rgba(89,217,157,1);
  border-radius: 16px;
  border:1px solid rgba(89,217,157,1);
  font-size: 13px;
  padding:4px;
  padding-left: 30px;
  padding-right: 15px;
  color: rgba(0,0,0,1);
  width: 100px;
}
.active-btn-red {
  background-color: rgb(231 127 108);
  border-radius: 16px;
  border: 1px solid rgb(231 127 108);
  font-size: 12px;
  padding: 4px;
  padding-left: 30px;
  padding-right: 15px;
  color: rgba(0,0,0,1);
  width: 100px;
}
#Ellipse_29 {
  opacity: 0.3;
  fill: rgba(0,0,0,1);
}
.Ellipse_29 {
  position: absolute;
  overflow: visible;
  width: 10px;
  height: 10px;
  left: 32px;
    top: 13px;
}
.Ellipse_29r {
  position: absolute;
  overflow: visible;
  width: 10px;
  height: 10px;
  left: 32px;
  top: 13px;
}
input.ng-invalid, select.ng-invalid, mat-select.ng-invalid {
  border: 1px solid red!important;
}
.hrscroll-table{
  margin-top: 8px;
  overflow-x: auto;
  width:auto;
}
.fixed-sidel{
  position: -webkit-sticky;
  position:sticky;
  background-color: #fafcfd!important;
  right: 0 ;
  z-index: 1;
  opacity:1!important;
}
.bluebg{
  background-color: #03080A!important;
}
.bluetextcolor{
  color:#055da8!important;
}

.table-striped > tbody > tr:nth-child(2n+1) > .fixed-sidel{
  background-color: #F2F2F2!important;
  right: 0 ;
  position: -webkit-sticky;
  position:sticky;
  opacity:1!important;
  z-index: 1; 
}

.pic{
  width:50x;
}
.table-list-user {
  width: 28px;
  height: auto;
}

.total{
  color: rgba(32,56,100,0.502);
  font-size: 12px!important;
}

.b-number{
  font-weight: bold;
  font-size: 21px;
  color: #003385;
  padding-top: 8px;
}
.b-content{
fill: rgba(255,255,255,1);
margin-top: 2px;
}
.active-user{
    font-size: 14px;
    color:#03080A!important;
    margin-bottom: -6px!important;
}
/* dropdown btn */
.dropbtn {
  background-color: #295BDC !important;
  color: white !important;
  padding: 6px;
  padding-left: 13px;
  padding-right: 13px;
  font-size: 13px!important;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}


.dropdown {
  position: relative;
  display: inline-block;
}



.dropdown:hover .dropbtn {
  background-color: #295BDC;
}
.bulk-arrow{
  color:white;
  padding-left: 2px;
}

.bluebtn{
  margin-right: 5px;
  margin-left: 5px;
  background-color: #F58029 !important;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px!important;;
  color:white!important;
  padding:6px 20px!important;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
.blue{
  background-color: #295BDC;
    padding: 6px 20px!important;
    white-space: nowrap;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);

}
.bluebtn:hover{
  background-color: #F58029;
  color:white!important;
  padding:6px 20px!important;
}
#search-user {
  overflow: visible;
  position: absolute;
  width: 17px;
  height: 24px;
  left: 771px!important;
    top: 7px;
  transform: matrix(1,0,0,1,0,0);
  color:#295bdc;
}
.searchinputuser:focus, input:focus{
  outline: none!important;
}
.searchinputuser{
  width: 210px;
    border-radius: 5px;
    border: none!important;
    padding: 5px;
    padding-left: 10px;
    background-color: rgba(240,240,240,1);
}
.searchuser-bar{
  text-align: end;

}
.right-drop{
  text-align: end;
  padding-right: 3px!important;

}
.edit-btn-user{
  padding-left: 35px;
  color: white !important;
}
.text-white{
  color: white !important;
}

/* table */
.searchinputuser::placeholder {
  color: rgba(41,91,220,1);
  opacity:0.7;
}

.btwn-space{
  margin-left: 13px!important;

}
.img-size{
  width: 22px;
  height: 24px;
  border: none!important;
}
.img-group{
  width: 14px!important;
    height: auto;
}
#search-user path {
  fill: rgba(41,91,220,1);
}

/* add user */
.user_name_edit
{
    height: 66px;
    margin-bottom: -15px;
    overflow: visible;
}
#content-insideuser{
  padding: 13px;
}
#FirstName{
  left: 34px;
  top: 3px;
  position: absolute;
  overflow: visible;
  width: 90px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226);
}
#UserDetails{
  left: 61px;
  top: 248px;
  position: absolute;
  overflow: visible;
  width: 131px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 18px;
  color: #003385;
}
#hierarchysec{
  left: 61px;
  top: 547px;
  position: absolute;
  overflow: visible;
  width: 111px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 18px;
  color: #003385;
}
#groups{
  left: 61px;
  top: 687px;
  position: absolute;
  overflow: visible;
  width: 83px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 18px;
  color: #003385;
}
#Dept{
  left: 34px;
  top: 4px;
  position: absolute;
  overflow: visible;
  width: 102px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color: #003385;
}
#Div{
  left: 34px;
  top: 73px;
  position: absolute;
  overflow: visible;
  width: 74px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color: #003385;
}
.image_rect_edit-userDetails {
  opacity: 0.7;
  background-color: white;
  border: 1px solid  #94969b;
  border-radius: 4px;
}
.image_rect_edit-hierarchysec{
  opacity: 0.7;
  height: 115px;
  background-color: white;
  margin-top: 19px;
  border: 1px solid #94969b;
  border-radius: 4px;
}
.image_rect_edit-groups{
  opacity: 0.7;
  height: 194px;
  background-color: white;
  margin-top: 19px;
  border: 1px solid #94969b;
  border-radius: 4px;
}
#content-insidehierarchysec{
  padding: 10px;
}
#content-insideuserDetails {
  padding: 10px;
}
#content-insidegroups{
  padding: 10px;
}
#LastName{
  left: 34px;
  top: 3px;
  position: absolute;
  overflow: visible;
  width: 91px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226);
}
.org_name_edit2{
  height: 66px;
  margin-bottom: -15px;
  overflow: visible;
}

.org_name_edit3 {
  margin-bottom: -15px;
  width:100%;
  height: 66px;
  overflow: visible;
}
.img-height_user {
  height: 130px;
  text-align: center;
}
.image-upload-user {
  margin-top: 44px;
}
.division-user{
  margin-left:20px;
}
.sec1{
  margin-right:10px;
}
.sec2{
  margin-right:10px;
}
.sec3{
  margin-right:10px;
}
.image_rect_edit-user {
  opacity: 0.7;
  height: 52px;
  background-color: white;
  margin-top: 19px;
  border: 1px solid black;
  border-radius: 4px;
}

#Department{
  left: 34px;
  top: 3px;
  position: absolute;
  width: 116px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226);
}
#leve3 {
  left: 34px;
  top: 4px;
  position: absolute;
  width: 156px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color: #003385;
}
#Email{
  left: 34px;
  top: 72px;
  position: absolute;
  overflow: visible;
  width: 134px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226);
}

#ID{
  left: 34px;
  top: 72px;
  position: absolute;
  overflow: visible;
  width: 63px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226);
}
#UserList {
  padding-top: 3px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(0,0,0,1);

}
.org-user-export {
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(229,179,44,1);
  text-transform: uppercase;
}
#elements {
  white-space: nowrap;
  text-align: left;
  text-transform:capitalize;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: rgba(0,0,0,1);
  padding-left: 0px;
}
.search-icon-elements {
      position: absolute;
      top: 54%;
      right: 135px;
      margin-top: -10px;
      color: rgba(41,91,220,1)!important;
}
.quest-color{
  background-color: #A7BCFA;
    color: rgb(13 72 226)!important;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 18px;
}
.attr-color{
  background-color: #E9BAAC!important;
  color: #d83c0d!important;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 18px;
}
.goal-color{
  background-color: #BFDBD5!important;
  color: #038066!important;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 33px;
}
.element-input{
  padding: 5px;
  border: 1px solid black;
  border-radius: 4px;
}
.rotate-element{
  transform: rotate(180deg);
}
.element-drop{
    padding: 5px;
    border-radius: 8px;
    width: 32px;
}
.addnew{
  margin-top: 10px;
}

#LastLogin{
  left: 34px;
  top: 143px;
  position: absolute;
  overflow: visible;
  width: 95px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226);
}
#CompanyName{
  left: 34px;
  top: 143px;
  position: absolute;
  overflow: visible;
  width: 135px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226);
}

#location {
  left: 34px;
  top: 4px;
  position: absolute;
  overflow: visible;
  width: 84px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color: #003385;
}
#Division {
  left: 34px;
  top: 284px;
  position: absolute;
  overflow: visible;
  width: 68px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color: #003385;
}
#Manager{
  left: 34px;
  top: 143px;
  position: absolute;
  overflow: visible;
  width: 124px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226);
}#Phone{
  left: 34px;
  top: 4px;
  position: absolute;
  overflow: visible;
  width: 210px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226)!important;
}
#UserType{
  left: 34px;
  top: 145px;
  position: absolute;
  overflow: visible;
  width: 84px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226);
}
#JobTitle{
  left: 34px;
  top: 72px;
  position: absolute;
  overflow: visible;
  width: 78px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226);
}
#AccoutModified{
  left: 34px;
   top: 5px;
  position: absolute;
  overflow: visible;
  width: 70px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226);
}
#Entity {
  left: 34px;
  top: 3px;
  position: absolute;
  /* overflow: visible; */
  width: 116px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color: #003385;
}
#Group{
  left: 34px;
  top: 73px;
  position: absolute;
  overflow: visible;
  width: 49px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 14px;
  color:rgb(13 72 226);
}
.import{
  transform: rotate(
    -90deg
    );
}
.direct-report{
  transform: rotate(
-90deg
);
}
.status{
  color:rgb(13 72 226);
}
.radio{
  margin-left: 0px;
}
.inactive{
  margin-left: 59px!important;
}
.inactive-edit {
  margin-left: 66px!important;
}
.ac-labl{
  margin-left: 10px;

}
.inac-labl-edit {
  margin-left: 67px;
}
.inac-labl{
  margin-left: 62px;
}
.btnsec{
  margin-top: 30px;
  margin-bottom: 20px;
}
.send{
  margin-top: 24px;

}
.margin-cancel{
  margin-right: 20px;
}
.deactivate .mat-dialog-container{
  position: absolute;
  top: 164px;
  left: 327px;
  width: 434px!important;
  height: 283px!important;
  overflow: none!important;
  padding: -10!important;
}
.mat-dialog-container{
  /* overflow: hidden!important; */
  padding: 35px!important;
}
.hv-scroll{
  display: block!important;
  height: 219px!important;
  overflow-y: auto!important;
  margin-top: 30px;
  overflow: auto;
  
}
.hv-scroll-change {
  display: block!important;
  /* height: 179px!important; */
  overflow-y: auto!important;
  /* margin-top: 30px; */
  overflow: auto;
}
.buttonactonlength{
  min-height: 105px!important;
}
#search-blue-user {
  overflow: visible;
  position: absolute;
  width: 17px;
  height: 24px;
  left: 771px!important;
  top: 7px;
  transform: matrix(1,0,0,1,0,0);
  color:#295bdc;
}
/* .ngx-select__choices{
  position: relative!important;
} */
#search-blue-user path {
  fill: rgba(41,91,220,1);
}
#search-blue-user-feedback {
  overflow: visible;
  position: absolute;
  width: 17px;
  height: 24px;
  left: 289px;
  top: 5px;
  transform: matrix(1,0,0,1,0,0);
}
#search-blue-user-feedback path {
  fill: rgba(41,91,220,1);
}
#search-blue-user-survey path {
  fill: rgba(41,91,220,1);
}

#search-blue-user-survey {
  overflow: visible;
  position: absolute;
  width: 17px;
  height: 24px;
  left: 666px;
  top: 14px;
  transform: matrix(1,0,0,1,0,0);
}
.usersearch-feedback {
  margin-right: 21px;
}
/* .addfeed{
  font-size: 14px!important;

} */
.addfeed:hover{
  color: white;
  }
.addgroup:hover{
color: white;
}
/* .addgroup{
  margin-right: 15px;
} */
.addright{
  text-align: end;
  
}
.usersearch{
  margin-right: 2px;
}
.eventspace{
  padding-bottom: 5px;
  padding-left: 15px!important;

}
.eventspacequest {
  padding-bottom: 5px;
  /* padding-left: 15px!important; */
}
.eventspaceind{
  padding-bottom: 5px;
}
.eventspace_input {
  padding-bottom: 5px;
  padding-right: 20px;
}
.eventspacegroup {
  margin-left: -15px;
  padding-bottom: 5px;
}
.surveyform{
  margin-bottom: 10px;
}
.btnsurvey{
  margin-top: 20px;
  margin-bottom: 20px;
}
.row-space{
  margin-right:0px!important;
  margin-top:5px;
  padding-bottom: 5px!important;
}
.h-name-user{
  padding-left: 40px!important;
}
.name-user{
  padding-left: 17px;
}
.type-user{
  padding-left: 88px;
}
.purpose-user{
  padding-left: 17px;

}
.remark-user{
  padding-left: 40px;

}
.on-user{
  padding-left: 40px;
}
.by-user{
  padding-left: 106px;
}
.user-pro{
  padding-right:5px;
}
.t-user{
  padding-left: 55px!important;
}
.pur-user{
  margin-right: 37px!important;
  margin-left: 13px!important;
}
.o-user{
  padding-left: 55px!important;
}


.b-user{
  margin-left: 55px!important;
}
.r-user{
  margin-right: -50px!important;
}
.org-user{
  margin-top: 15px;
  white-space: nowrap;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(229,179,44,1);
    text-transform: uppercase;

}

.review-list-left-side{
  margin-top: 4px;
  white-space: nowrap;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(229,179,44,1);
    text-transform: uppercase;

}

.first-member{
  padding-left: 38px!important;
}
.f-member{
  padding-left: 14px!important;
}
.rc-user {
  margin-right: 18px!important;
  margin-left: -64px;
}
.tc-user {
  padding-left: 0px!important;
  margin-right: -83px;
}
.bc-user {
  margin-left: 125px!important;
}
.oc-user {
  padding-left: 96px!important;
  /* padding-left: 120px!important; */
}
.addnew-user {
  text-align: left;
  color: rgb(0, 0, 0);
  font-size: 17px;
  padding-bottom: 5px;
}
.blue-heading{
  /* font-size: 16px;
  color: #003385; */
    font-size: 14px;
    color: #295bdc;
    font-weight: 600;
    padding: 4px 0;
    white-space: nowrap;
}
.filter-resettodos
{
  background-color: #333;
  color: white;
  padding: 3px;
  border-radius: 6px;
  margin-top: 0px;
  margin-right: 5px;
}
.addright{
  text-align: end;
  box-sizing:none !important;
  padding-right: 0px!important;
  /* padding-top: 8px!important; */
}
.table-count
{
  color:#DC8040;
  font-size: 15px;
  text-transform: capitalize;
}
.addright-margin{
  margin-left: 8.2%;
}
@media screen and (min-width: 768px) and (max-width: 850px) { 
  .table-count {
    color: #E4A802;
    font-size: 13px;
    text-transform: capitalize !important;
}
}
.export-report-rep {
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 13px;
  background-color: #295BDC;
  padding: 6px 20px!important;
  font-size: 13px!important;
  color: white!important;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.img-addu {
  width: 17px!important;
  /* width: 21px!important; */
  height: auto;
}

.addrightbtn {
  text-align: end;
  margin-left: 611px;
}
.btn-user{
  padding-left: 0px!important;
}
.small-st{
  margin-left: 34px;
}
.small-ll{
  margin-left: 55px;
}
.small-bt{
  text-align: end;
  margin-left: -26px;
}

.btn-sm-user{
  padding: 2px 3px!important;
  font-size: 12px!important;
}
.h-name-useradmin {
  padding-left: 32px!important;
}

.send > input[type=radio] {
  border: 1px solid rgba(41,91,220,1);
  padding: 0.5em;
  -webkit-appearance: none;
}

.send > input[type=radio]:checked {
  background:rgba(41,91,220,1) no-repeat center center!important;
  background-size: 9px 9px;
}
input[type=radio]:checked {
  background:#005cc8 no-repeat center center!important;
  background-size: 9px 9px;
}
.send > input[type=radio]:focus {
  outline-color: transparent;
}
.admin-select{
  /* width: 264px; */
  width: 100%;
  background-color: white;
}
.admin-select:hover{
  border: none!important;
}
.edit-user {
  margin-right: 5px;
}
.mat-dialog-content {
  overflow: hidden!important;
}
/* .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-bottom: 62px!important;
} */
.blue-text{
  color:rgba(41,91,220,1);
}
.adminPop .mat-dialog-container{
  position: absolute;
  top: 164px;
  left: 428px;
  width: 483px!important;
  height: 326px!important;
  overflow: none!important;
  padding: -10!important;

}
.deletePop .mat-dialog-container{
  top: 139px;
  left: 419px;
  width: 450px!important;
  height: 262px!important;
  padding: 31px!important;
  overflow: none!important;
  padding: -10!important;

}
.deletePops .mat-dialog-container{
  top: 35%;
  left: 35%;
  width: 450px!important;
  height: 299px!important;
  padding: 31px!important;

}

@media (min-width: 764px) and (max-width: 1023px) {

  .deletePops .mat-dialog-container{
    top: 35%;
    left: 25%;
    width: 450px!important;
    height: 299px!important;
    padding: 31px!important;
  
  }
}
@media (min-width: 1024px) and (max-width: 2650px) {

  .deletePops .mat-dialog-container{
    top: 30%;
    left: 40%;
    width: 450px!important;
    height: 299px!important;
    padding: 31px!important;
  
  }
}


.errorDialog .mat-dialog-container{
  top: 35%;
  left: 35%;
  width: 450px!important;
  height: 299px!important;
  padding: 0px!important;
  border-radius: 8px;

}

@media (min-width: 764px) and (max-width: 1023px) {

  .errorDialog .mat-dialog-container{
    top: 35%;
    left: 25%;
    width: 450px!important;
    height: 299px!important;
    padding: 0px!important;
  
  }
}
@media (min-width: 1024px) and (max-width: 2650px) {

  .errorDialog .mat-dialog-container{
    top: 30%;
    left: 40%;
    width: 450px!important;
    height: 299px!important;
    padding: 0px!important;
  
  }
}


.addmember .mat-dialog-container{
  top: 115px;
    left: 316px;
    width: 637px!important;
    height: 370px!important;
  padding: 31px!important;
  overflow: none!important;
  padding: -10!important;

}

.changeHierarchy .mat-dialog-container{
  position: absolute;
  top: 10px;
  left: 128px;
  width: 80%!important;
  /* width: 1145px!important; */
  height: 90%!important;
  overflow: none!important;
  padding: -10!important;

}
.labelhead{
  font-weight: bold;
  margin-right:15px;
  display: block;
}
.add-member-length{
  
    margin-bottom: 23px;
}
.add-group-length{
  margin-bottom: 10px;
}
.allign-g{
  text-align: left;
}

/* .tab .nav-tabs li[class="active"] a #Path_159 {
  fill:rgba(41,91,220,1);
} */
/* .tab .nav-tabs li[class="active"] a #Path_157 {
  fill:rgba(41,91,220,1);
 
}

.nav-tabs>li.active>a:focus #Path_159 {
  fill:rgba(41,91,220,1);
 
}
.tab .nav-tabs li[class="active"] a #Path_155 {
  fill:rgba(41,91,220,1);
}
.tab .nav-tabs li[class="active"] a #Rectangle_202 {
  fill:rgba(41,91,220,1);
}
.tab .nav-tabs li[class="active"] a #Rectangle_203 {
  fill:rgba(41,91,220,1);
}
.tab .nav-tabs li[class="active"] a #eventsfill {
  fill:rgba(41,91,220,1)!important;
} */
#search-con {
  overflow: visible;
  position: absolute;
  width: 17px;
  height: 24px;
  left: 507px;
  top: 7px;
  transform: matrix(1,0,0,1,0,0);
  color: #295bdc;
}
#search-con path {
  fill: rgba(41,91,220,1);
}

.drop-select{
    /* width: 298px;
     */
     width: 77%;
    height: 35px;
    background-color: white;
    border: 1px solid #7a7a7a54!important;
}
.drop-select-add {
  width: 398px;
  height: 35px;
  background-color: white;
}
.bold-text{
  font-weight: bold;
}

.rowlength-hierarchy{
  margin-bottom: 15px;
}
.subheading-hrchy {
  font-size: 14px;
  font-size: bold;
}
.choose-user{
    /* width: 499px; */
    padding-bottom: 3px !important;
    height: 31px;
    background-color: rgb(238, 245, 249, 100%) !important;
    border: 1px solid #ccc !important
}
.choose-padding{
  margin-right: 33px;
}
.choose-mar{
  margin-left: -63px;
}
.dep-text{
  color:rgba(41,91,220,1);
  font-size: 12px;
}
.dep-content{
  font-size: large;
  margin-top: -6px;

}
.team-div{
  margin-left: -47px;
}
.dept-div{
  padding-left: 46px!important;
}
.btns-row{
  margin-top:0px;
  margin-bottom: 0px!important;
}
.change-manager{
  background-color:rgba(41,91,220,1);
  color:white;
  padding: 10px 17px!important;
  font-size: 12px!important;
}
.add-dir-report-btn{
  /*padding: 10px 17px!important; */
  font-size: 13px!important;
  color:whitte!important;
}
#search-blue-direct {
  overflow: visible;
  position: absolute;
  width: 17px;
  height: 24px;
  left: 767px!important;
  top: 5px;
  transform: matrix(1,0,0,1,0,0);
}
#search-blue-direct path {
  fill: rgba(41,91,220,1);
}
.user-list-row{
  margin-right: -42px!important; 
  padding-top: 7px!important;
}

.view-dir{
  background-color: #203864;
}
.dir-report{
  background-color: #3665de;
}
.img-close-dir {
  width: 21px!important;
  height: auto;
}
.add-dir-report-btn:hover{
  color: white!important;
}
.change-manager:hover{
  color: white!important;
}





/* user prodile section */
.img-profilepic{
  
  width:auto  ;
  height:170px;
  border-radius: 5px;
}
.picdiv{
  text-align: end;
}
.btn-profile{
  padding-top: 15px;
}
.padding-pro{
  padding-top: 5px!important;

}
#Name-up {
  left: 32px;
  top: 26px;
  position: absolute;
  overflow: visible;
  width: 103px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
#Address-up {
  position: absolute;
  width: 79px;
  height: 29px;
  left: 35px;
  top: 312px;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
#Email-up {
  position: absolute;
  width: 59px;
  height: 29px;
  left: 35px;
  top: 115px;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
#Company-up {
  position: absolute;
  width: 154px;
  height: 29px;
  left: 35px;
  top: 269px;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
#Dept-up {
  left: 32px;
  top: 352px;
  position: absolute;
  overflow: visible;
  width: 112px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
#Team {
  left: 32px;
  top: 508px;
  position: absolute;
  overflow: visible;
  width: 59px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
#Name-last-up {
  left: 32px;
  top: 27px;
  position: absolute;
  overflow: visible;
  width: 102px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  /* color: rgba(41,91,220,1); */
  color: #003385;
}
#UserType-up {
  position: absolute;
  /* padding-top: 20px; */
  width: 96px;
  height: 29px;
  left: 35px;
  top: 114px;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
.org_name-up {
  /* position: absolute; */
  width: 271px;
  height: 66px;
  overflow: visible;
  /* margin-top: 20px; */
}

.org-up {
  
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(229,179,44,1);
  text-transform: uppercase;
}
.org-up-mar{
  margin-top: 44px;
}
/* .tab .nav-tabs li[class="active"] a #Path_203 {
  fill:rgba(41,91,220,1);
} */
/* .tab .nav-tabs li[class="active"] a #Path_204 {
  fill:rgba(41,91,220,1);
}
.tab .nav-tabs li[class="active"] a #Path_202 {
  fill:rgba(41,91,220,1);
} */

#event-icon {
  transform-origin: center;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 3px;
  top: 7px;
  overflow: visible;
  fill: white;
}
#files-icon{
  transform-origin: center;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 3px;
  top: 7px;
  overflow: visible;
  fill: white;
}
#user-details-icon{
  transform-origin: center;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 3px;
  top: 7px;
  overflow: visible;
}

  .bg-orange-up {
    background-color: rgba(242,113,39,1);
    padding: 5px 10px!important;
    white-space: nowrap;
    text-align: left;
    border-radius: 4px!important;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(255,255,255,1);
}



.img-download{
  width: 17px!important;
  height: auto;
  transform: rotate(
    -180deg
    );
  
}

.tab .nav-tabs .settings-li a:before
{
  border-bottom: none!important;
}
.tab .nav-tabs .settings-li a {
  margin-left: 0px!important;
}
.tab .nav-tabs .settings-li .interns-li :hover {
  /* color: rgba(41,91,220,1)!important; */
  color:#E4A802!important;
  background-color: white!important;
}
/* .dropdown-menu>li>a :hover{
  background-color:#1292BE;
} */
.settings-li >  li > .dropdown-menu > li:hover
  {
    background-color:#1292BE;
  }
/* .tab .nav-tabs .settings-li a {
  font-weight: 200!important;
} */

#settings-style{
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: rgba(0,0,0,1);
  /* padding-left: 16px; */
  padding-top: 5px;
  padding-bottom: 5px;
}


input[type=radio] {
  border-radius: 12px;
  height: 16px;
  width: 17px;

}
.text-enable{
  padding-left: 10px;
  margin-right: 13px;
  font-weight: 100;
  margin-bottom: 0px;
}
.radiobtn{
color: #295bdc !important;
}
.level-inner{
  background-color: rgb(238, 245, 249,0.3)!important;
  /* padding-top:10px;
  padding-bottom:10px; */
}
.inner-padding{
  padding-top:10px;
  padding-bottom:10px;
}
.main-color{
  padding-top:10px;
  padding-bottom:10px;
  background-color: #eef5f9!important;
}
.padding-innerr{
  padding-left: 67px;
}
.font{
  font-weight: bold!important;
  font-size: 16px!important;
  color: rgba(0,0,0,1)!important;
  
}
.spaceleft{
  padding-left:  40px!important;
}
.send-email {
  height: 17px;
  width: 17px;
}
.check-sec-top{
  padding-top: 15px;
}
.check-sec-bottom{
  padding-bottom:10px;
  padding-left:41px;
}
.colorbg{
  background-color: rgb(240, 240, 240,0.6)!important;
  /* opacity: 0.6!important; */
}

/* check style */

 .check .check-sec-top input[type="checkbox"]:checked + label::after  {
  content: "";
  position: absolute;
  left: -37px;
  top: 9px;
  width: 8px;
  height: 8px;
  background-color: rgba(242,113,39,1)!important;
  /* border-radius: 3px!important; */
}
.check .check-sec-top  label::before {
  content: "";
  position: absolute;
  top: 6px;
  left: -40px;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: 1px solid #777;
  /* border-radius: 4px; */
}
.check .check-sec-top label {
  position: relative;
  margin-left: 40px;
  font-weight: bold;
  cursor: pointer;
}
.check .check-sec-top input[type="checkbox"] {
  appearance: none;
}

/* field version page */
.check .check-sec input[type="checkbox"]:checked + label::after  {
  content: "";
  position: absolute;
  left: -37px;
  top: 9px;
  width: 8px;
  height: 8px;
  background-color: rgba(242,113,39,1)!important;
  /* border-radius: 3px!important; */
}
.check .check-sec  label::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -40px;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: 1px solid #777;
  /* border-radius: 4px; */
}
.check .check-sec label {
  position: relative;
  margin-left: 40px;
  font-size: 14px;
  font-weight:normal;
  cursor: pointer;
}
.check .check-sec input[type="checkbox"] {
  appearance: none;
}
.check>tr>td{
  text-align: center!important;
}
.check .check-sec-con label::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -40px;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: 1px solid #777;
  /* border-radius: 4px; */
}
.check .check-sec-con input[type="checkbox"]:checked + label::after  {
  content: "";
  position: absolute;
  left: -37px;
  top: -1px;
  width: 8px;
  height: 8px;
  background-color: rgba(242,113,39,1)!important;
  /* border-radius: 3px!important; */
}
.check .check-sec-con label {
  position: relative;
  margin-left: 40px;
  font-size: 14px;
  font-weight:normal;
  cursor: pointer;
}
.check .check-sec-con input[type="checkbox"] {
  appearance: none;
}


/* blue check */
.check .check-sec-blue label::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -40px;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: 1px solid #777;
  /* border-radius: 4px; */
}
.check .check-sec-blue input[type="checkbox"]:checked + label::after  {
  content: "";
  position: absolute;
  left: -37px;
  top: 8px;
  width: 8px;
  height: 8px;
  background-color: #005cc8!important;
  /* background-color: rgba(41,91,220,1)!important; */
  /* border-radius: 3px!important; */
}
.check .check-sec-blue label {
  position: relative;
  margin-left: 15px;
  font-size: 14px;
  font-weight:normal;
  cursor: pointer;
}
.check .check-sec-blue input[type="checkbox"] {
  appearance: none;
}

/* check styled ends */

.fields-head{
  text-align: center!important;
}
/* .table>thead>tr>td, .table>thead>tr>th {
font-weight: bold!important;
border-top: none!important;
} */
.first-allign{
  text-align: left!important;
}
.eventpadding{
  padding-top: 10px;
}

/* table>tbody>tr>td:nth-child(odd){ */
  /* .table-striped > tbody :nth-child(2n+1){
  background-color: #0fe0be;
}
table>tbody>tr:nth-child(odd){
  background-color: #FFF !important;
} */


/* .table-field tr td:nth-child(odd){ 
  background: #b8d1f3;
} */
/* .table-field tr td:nth-child(even){
  background: #fafcfd;
  z-index: 1000;
} */
.table-field>tbody>tr>td .check-content{
  text-align: center!important;
}

/* .table-field tr:nth-child(even) {
  background-color: #7c3535;
} */
.caret{
  transform: rotate(
    -90deg
    );
   
}
.padding-arrow{
  padding-right: 40px;
}
.expand-collapse{
  margin-left: -11px;
}



.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.dropdown-profilemenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 37px;
  margin-left: -53px;
  border-radius: 0 6px 6px 6px;
}

.feedsize{
    padding-left: 0px!important;
    font-size: 14px;
    font-weight: bold;
    color: #003385;
}
.feedsizegoal {
  /* padding-left: 0px!important; */
  font-size: 14px;
  font-weight: bold;
  color: #003385;
}
.feedsize-pad{
  padding-left: 0px!important;
}
.input-width{
  /* width: 630px; */
  width:100%;
  border: 1px solid #7a7a7a54!important;
  padding-left: 7px;
}

.due-date{
  width: 375px;
  /* border: solid 1px; */
  height: 40px;
  border:1px solid #7a7a7a54!important;
  padding: 4px;
  border-radius: 4px;
}
.due-date-user {
  /* width: 270px; */
  width: 100%;
  border: none;
  padding: 4px;
  border-radius: 4px;
  /* background-color: #92cbac42;
  padding-left: 5px!important; */
}
.drop-select-indi {
  width: 375px;
  height: 40px;
  border:1px solid #7a7a7a54!important;
  background-color: white;
}
.drop-select-indi:focus {
  /* border:1px solid #7a7a7a54!important; */
}
.user-drop .ngx-select__search {
  width: 375px!important;
  margin-left: -3px;
}
.user-drop .ngx-select_multiple {
  width: 375px!important;
}
.sur-margin{
  margin-top: 20px;
}
.addSurvey .mat-dialog-container{
  position: absolute;
  top: 20px;
  left: 13%;
  width: 68%!important;
  /* height: 586px!important; */
  }
/* .addSurvey .mat-dialog-container{
position: absolute;
top: 52px;
left: 327px;
width: 729px!important;
height: 544px!important;

} */

.addReviewStatus .mat-dialog-container {
  position: absolute;
  top: 52px;
  left: 327px;
  width: 654px!important;
  height: 350px!important;
}

#search-blue-userreport {
overflow: visible;
position: absolute;
width: 17px;
height: 24px;
left: 962px;
top: 14px;
transform: matrix(1,0,0,1,0,0);
}
#search-blue-userreport path {
fill: rgba(41,91,220,1);
}
.drop-select-report {
width: 311px;
height: 36px;
background-color: white;
}
.datepic{
background-color: #203864;
margin-left:10px;
margin-right:10px;
color:white;
}

.applyfilter {
  margin-right: 5px;
  margin-left: 0px;
  background-color: #295BDC;
  padding-left: 18px;
  padding-right: 17px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 13px!important;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
.report-head{
  white-space: nowrap;
  text-align: left!important;
  font-family: Poppins,Arial;
  font-style: normal!important;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  background-color: #03080a;
  text-transform: uppercase;
}

.drop-select-report-feed {
  width: 211px;
  height: 30px;
  background-color: white;
  border-color: #cfc9c9;
}

.download{
    width: 20px!important;
    height: auto;
}
.edit-color-yellow{
  background-color: #e2bb1f;
}
.search-icon-report {
  position: relative;
  /* top: 53%; */
  right: 30px;
  margin-top: -10px;
  color: rgba(41,91,220,1)!important;
}
#importdiv 
{ border: dashed 1px black;

height:168px; }
.choose-field{
  width: 343px;
  height: 32px;
    background-color:white!important;
}
.uploadimg{
  text-align: center;
  padding-left: 69px;
}
.upload-text{
  font-size: 8px;
    color: rgba(41,91,220,1);
    padding-left: 11px;
}
.upload-type{
  font-size: 12px;
  color: rgba(41,91,220,1);
  padding-left: 13px;
}
.upload-subhead{
  padding-top: 10px!important;
    padding-bottom: 10px!important;
    font-size: 13px!important;
}
.upload-fields{
  padding-bottom: 18px;
  color: rgba(41,91,220,1);
}
.upload-field-drop{
  margin-bottom: 10px;
}
.field-map{
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
}
.upload-field-req{
  margin-bottom: 10px;
  opacity: 0.3 !important;
}
.btnimport{
  margin-top:15px;
  margin-left: 0px!important;
}
.orangebtn :hover{
  color:white!important;
}
.fixed-sidel2 {
  position: sticky;
  padding-left: 24px!important;
  background-color: #fafcfd!important;
  right: 100px;
  z-index: 1;
  opacity: 1!important;
}
.fixed-sidel2-head {
  position: sticky;
  padding-left: 24px!important;
  background-color: #03080A!important;
  right: 100px;
  z-index: 1;
  opacity: 1!important;
}
.table-striped > tbody > tr:nth-child(2n+1) > .fixed-sidel2 {
  background-color:  #F2F2F2!important;
  right:100px;
  position: sticky;
  opacity: 1 !important;
  z-index: 1;
}
.action-padding{
  padding-right: 26px!important;
}
.import-logo path {
  fill: white;
}
.bluebtn-filter{
    margin-right: 15px;
    background-color: #295BDC;
    padding-left: 15px!important;
    padding-right: 17px!important;
    font-size: 14px;
    color: white;
    padding-top: 7px!important;
    padding-bottom: 7px!important;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}
.uploadsvg path{
  fill:white;
}
.box-details{
  border: 1px solid black;
  border-radius: 4px;
  margin-top:10px;
  padding-left: 20px;
  margin-left:0px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 0px!important;
}
.nme-space{
  padding-left: 33px;
}
.blue-head{
  color:#295BDC;
}
.big-num{
  font-size:38px;
}
.upload-margin{
  margin-top: 10px;
}
.selectedText{
  font-size:20px;
  padding-right: 10px;
  text-align: right;
  margin-left:40px;
}

.head-mar{
  margin-right: -81px;
}
.fontclass{
  font-size: 12px;
}
.btn-size{
  font-size: 11px!important;
}
/* date range picker */
/* commented for test */
.mat-form-field-appearance-fill .mat-form-field-flex {
  
  background-color: white!important;
  border:1px solid #7a7a7a54!important;

}
.mat-calendar-controls {
  display: flex;
  margin: 17% calc(33% / 7 - 16px)!important;
  padding-left: 20px!important;
}

.mat-form-field-underline {
  display: none!important;
}

.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  width: 194px;
  height: 12px!important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 4px 4px 0 0;
  padding: 0px!important;
  height: 35px!important;
}


.mat-datepicker-toggle-default-icon {

  height: -1.5em!important;
}
.mat-button-wrapper >svg{
  height:15px!important;
}
.mat-form-field-wrapper {
  padding-bottom: none!important;
}
.mat-datepicker-content .mat-calendar {
  width: 244px!important;
  padding-top: 10px;
  height: 225px!important;
}
.importimg svg #Path_154 {
  fill:white;
}
.addright a:hover{
  color:white;

}
.btnimport button:hover{
  color:white;
}
.feddback button:hover{
  color:white;
}
#search-blue-userreport-feed {
  overflow: visible;
  position: absolute;
  width: 17px;
  height: 24px;
  left: 1201px;
  top: 9px;
  transform: matrix(1,0,0,1,0,0);
}
#search-blue-userreport-feed path {
  fill: rgba(41,91,220,1);
  }


/* import user */
table.minimalistBlack {  
  border: 3px solid #000;  
  width: 100%;  
  text-align: left;  
  border-collapse: collapse  
}  

table.minimalistBlack td,  
table.minimalistBlack th {  
  border: 1px solid #000;  
  padding: 5px 4px  
}  

table.minimalistBlack tbody td {  
  font-size: 13px  
}  

table.minimalistBlack thead {  
  background: #cfcfcf;  
  background: -moz-linear-gradient(top, #dbdbdb 0, #d3d3d3 66%, #cfcfcf 100%);  
  background: -webkit-linear-gradient(top, #dbdbdb 0, #d3d3d3 66%, #cfcfcf 100%);  
  background: linear-gradient(to bottom, #dbdbdb 0, #d3d3d3 66%, #cfcfcf 100%);  
  border-bottom: 3px solid #000  
}  

table.minimalistBlack thead th {  
  font-size: 15px;  
  font-weight: 700;  
  color: #000;  
  text-align: left  
}  

table.minimalistBlack tfoot {  
  font-size: 14px;  
  font-weight: 700;  
  color: #000;  
  border-top: 3px solid #000  
}  

table.minimalistBlack tfoot td {  
  font-size: 14px  
}  
#Name-last-up-edit {
    left: 32px;
    top: 27px;
    position: absolute;
    overflow: visible;
    width: 116px;
    white-space: nowrap;
    text-align: left;
    font-style: normal;
    font-weight: lighter;
    font-size: 16px;
    color: #003385;
}
#Phone-up {
  position: absolute;
  width: 63px;
  height: 29px;
  left: 35px;
  top: 114px;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
#Jobtitle-up {
  position: absolute;
  width: 86px;
  height: 29px;
  left: 35px;
  top: 269px;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
#managersEmail-up {
  left: 32px;
  top: 352px;
  position: absolute;
  overflow: visible;
  width: 98px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
#Account-up {
  position: absolute;
  width: 175px;
  height: 29px;
  left: 35px;
  top: 269px;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
#Status-up {
  position: absolute;
  width: 69px;
  height: 29px;
  left: 35px;
  top: 269px;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
#LastModi-up {
  left: 32px;
  top: 352px;
  position: absolute;
  overflow: visible;
  width: 131px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
#Deptmt-up {
  left: 32px;
  top: 508px;
  position: absolute;
  overflow: visible;
  width: 117px;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #003385;
}
.input-edit-up {
  border: none !important;
  width: 250px!important;
}
.inputFile  {
  display: none!important;
}
.fileInputProfile  {
  display: block!important;
}
.closebtnedit{
  width: 35px;
  height: 31px;
}


/* test */

.Xs{
  border: red solid 1px;
  background-color: #ff7f7f;
}

.Ys{
  border: green solid 1px;
    background-color: #7fbf7f;
}

.Zs{
  border: blue solid 1px;
    background-color: #7f7fff;
}

input[value="Add X"]{
  background-color: #ff7f7f;
}

input[value="Add Y"]{
    background-color: #7fbf7f;
}

input[value="Add Z"]{
     background-color: #7f7fff;
}
.angular-editor[_ngcontent-dcm-c168] .angular-editor-wrapper[_ngcontent-dcm-c168] .angular-editor-textarea[_ngcontent-dcm-c168] {
 
  width: 700px!important;
}
.angular-editor-toolbar[_ngcontent-eks-c169]{

  width: 700px!important;
}
.newForm{
  padding-left: none!important;
}
.drop-select-form {
  width: 100%;
  height: 31px;
  background-color: white;
  border-color: #80808066;
}
wrapper>*, .mat-raised-button .mat-button-wrapper>*, .mat-icon-button .mat-button-wrapper>*, .mat-fab .mat-button-wrapper>*, .mat-mini-fab .mat-button-wrapper>* {
  vertical-align: middle;
}
.example-form-field {
  margin: 0 8px 16px 0;
}
.termhead{
  padding-top: 10px;
  padding-bottom: 0px;
  font-weight: bold!important;
}
.termscondition .mat-dialog-container{

  position: absolute;
  top: 52px;
  left: 319px;
  width: 735px!important;
  height: 441px!important;
  overflow:scroll!important;
  padding: -10!important;
}
.btmpadd{
padding-bottom: 10px;
}
.inactiveedit {
  margin-left: 53px!important;
}
.inac-labledit {
  margin-left: 55px;
}
.input-wid {
    width: 100%!important;
}
.goal > .ngx-select_multiple{
  width: 100%!important;
  border:none!important;
  margin-left: -2px;
}
.ashtext{
  color:rgba(145, 139, 139, 0.603);
}
.addadmins-reviews .ngx-select__selected {
  width:100%!important;
  border: 1px solid #80808066!important;
  height: 40px!important;
  border-radius: 4px!important;
  overflow: hidden!important;
}
.addadmins-reviews .ngx-select {
width: 100%; 
}
.addadmins-reviews  .ngx-select__search {
  width: 100%!important;
}
.addadmins-reviews .ngx-select__choices {
  height: 110px!important;
  width: 100%!important;
}
.addusers .ngx-select__selected {
  width: 550px;
  border: 1px solid #80808066!important;
  height: 40px!important;
  border-radius: 4px!important;
  overflow: hidden!important;
}
.addadmins-goal .ngx-select__selected {
  width:761px;
  border: 1px solid #80808066!important;
  height: 40px!important;
  border-radius: 4px!important;
  overflow: hidden!important;
} 

.addadmins-goal  .ngx-select__search {
  width: 98.5%!important;
}
.addadmins-goal .ngx-select__choices {
  height: 110px!important;
  width: 98.5%!important;
}
.addadmins-goalreview .ngx-select__selected {
  width: 100%!important;
  border: 1px solid #80808066!important;
  height: 40px!important;
  border-radius: 4px!important;
  overflow: hidden!important;
}
.addadmins-goalreview .ngx-select {
  width: 100%!important;
}
.datepic-goal > .mat-form-field-appearance-fill .mat-form-field-flex {
  width: 763px!important;
  border: 1px solid #80808066!important;
}
.singlesel .ngx-select__selected {
  border-radius: 4px!important;
  overflow: hidden!important;
} 

.user-drop-con .ngx-select__toggle {
  width: 375px!important;
  border:1px solid #7a7a7a54!important;
  height: 15px!important;
  padding-top: 10px;
}
.user-drop-con  .ngx-select__choices{
  width: 376px!important;
}
.user-drop-con .ngx-select__search{
  width: 376px!important;
}
.ngx-select__toggle {
  padding-top: 10px;
}
.mar-left-div{
  margin-left: 0px;
}
.cancel-btn:hover{
  background-color: #d8dae0;
}
.red-text-row{
  color:rgb(216, 72, 72)
}
.tooltip { pointer-events: none; }
.icon-delete
{
  padding: 3px !important;
  background-color: #EC002A !important;
  border-radius: 3px;
}
.icon-expand-collapse
{
  padding: 3px !important;
  background-color: #be721b !important;
  border-radius: 3px;
}
.icon-copy
{
  padding: 3px !important;
  background-color: #f27127 !important;
  border-radius: 3px;
}
.icon-view
{
  padding: 3px !important;
  background-color: #203864 !important;
  border-radius: 3px;
}
.icon-not-assigned
{
  padding: 3px !important;
  background-color: #5a6170;
  border-radius: 3px;
  width: 26px;
    height: 26px;
    /* cursor: pointer; */
}

.icon-users
{
  padding: 3px !important;
  background-color: #295BDC;
  border-radius: 3px;
  width: 26px;
    height: 26px;
    /* cursor: pointer; */
}

.icon-filter
{
  padding: 5px;
  background-color: #295BDC;
}
.icon-template--shared
{
  background: #46B800;
  padding: 2px !important;
  border-radius: 3px;
  width: 26px;
  height: 26px;
}

.icon-template-user-allocated
{
  background: #93A3BC;
  padding: 2px !important;
  border-radius: 3px;
  width: 26px;
  height: 26px;
}

.icon-template--recalled
{
  background: #898989;
  padding: 2px !important;
  border-radius: 3px;
  width: 26px;
  height: 26px;
}
.icon-template-notshared
{
  background: #EC002A;
  padding: 2px !important;
  border-radius: 3px;
  width: 26px;
  height: 26px;
}

.icon-addUsers
{
  background: #F37028;
  padding: 2px !important;
  border-radius: 3px;
  width: 26px;
  height: 26px;
}
.count-reviews
{
  background-color: silver !important;
    color: #000;
    font-weight: 700;
    border-radius: 13%;
    padding: 5px !important;
    width: 50px;
    height: 25px;
    text-decoration: none;
    display: inline-block;
    line-height: 15px;
    text-align: center;
}
.compCount .mat-dialog-container{
  top: 25%;
  left: 30%;
  width: 650px!important;
  height: 360px!important;
  padding: 31px!important;
  overflow: hidden !important;
}


.validationDialog .mat-dialog-container{
  top: 8%;
  left: 30%;
  width: 650px!important;
  height: 530px!important;
  padding: 31px!important;
  overflow: hidden !important;
}


.hr-status
{
  border-radius: 50%;
    color: #f44336;
    width: 25px;
    height: 25px;
    letter-spacing: 0.5px;
    padding-top: 4px;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    margin-left: 2px;
    border: 2px solid red;
}


@media screen and (min-width:769px) and (max-width:853px) {
  
  .compCount .mat-dialog-container{
    top: 25%;
    left: 12%;
    width: 650px!important;
    height: 360px!important;
    padding: 31px!important;
    overflow: hidden !important;  }

}

.validationDialog .mat-dialog-container{
  top: 8%;
  left: 12%;
  width: 650px!important;
  height: 530px!important;
  padding: 31px!important;
  overflow: hidden !important;
}

@media screen and (min-width:854px) and (max-width:1024px) {
  
  .compCount .mat-dialog-container{
    top: 25%;
    left: 20%;
    width: 650px!important;
    height: 360px!important;
    padding: 31px!important;
    overflow: hidden !important;  }

}

.validationDialog .mat-dialog-container{
  top: 8%;
  left: 25%;
  width: 650px!important;
  height: 530px!important;
  padding: 31px!important;
  overflow: hidden !important;
}

@media screen and (min-width:1800px) and (max-width:2000px) {
  
  .compCount .mat-dialog-container{
    top: 25%;
    left: 35%;
    width: 650px!important;
    height: 360px!important;
    padding: 31px!important;
    overflow: hidden !important;
  }

  .validationDialog .mat-dialog-container{
    top: 8%;
    left: 35%;
    width: 650px!important;
    height: 530px!important;
    padding: 31px!important;
    overflow: hidden !important;
  }

}

.count-elements {
  color: #e4a802;
  font-size: 15px;
}
.barcolor {
  font-size: 21px;
  color: #c3bebe;
}
.table-border
{
  border: 2px solid ;
}

.add-label
{
    font-weight: 600;
    color: #003385!important;
}
.inline
{
    display:inline-block !important;
}
.select-dropdown {
  margin-bottom: 12px;
  width: 150px !important;
  display: inline-block !important; 
  min-height: 33px !important;
  height: 33px !important;
  border-color: #cbc3c3 !important;
  text-align: left !important;
}
.txt-al-c
{
  text-align: center !important;
}
.pd-14
{
  padding: 14px;
}
.comp-applicable
{
  margin-top: 9px;
}



.image
{

  transform: rotate(71deg);
  width: 27px;

}

.flag-360-checkin
{
  border-radius: 50%;
  padding: 4px;
  width: 23px;
  margin-right: 2px;

}

.pd-35
{

  padding: 14px;
  padding-left: 93px;
  padding-top: 3px;
  padding-bottom: 27px;
  padding-right: 0;

}

.value-head
{

  font-weight: 800;
  color: #000 !important;
}

.pd-l-78
{
  padding-left: 78px !important;
}

.pd-l-65
{
  padding-left: 65px !important;
}
.pd-l-15
{
  padding-left: 15px !important;
}


.mat-datepicker-content .mat-calendar {
  width: 249px !important;
  padding-top: 10px;
  height: 249px !important;
}
